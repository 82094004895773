import { FaInstagram, FaFacebook, FaYelp, FaCopyright } from "react-icons/fa";
import { Link } from "react-router-dom";
import shopInfo from "./shopInfo";
import getImages from "./GetImages";
import Button from "react-bootstrap/Button";
import EmailUs from "./EmailUs";

function Footer() {
  const phoneNum = `tel:${shopInfo.phone}`;
  const email = `mailto:${shopInfo.email}`;
  const randomImages = getImages();
  const firstNine = randomImages.slice(0, 9);

  return (
    <>
      <EmailUs />
      <section id="footer" className="footer_area">
        <div className="container-md pt-60 pb-60">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="footer_about pb-30">
                <h3 className="footer_title">Contact Us</h3>
                <p>
                  <a href={phoneNum}>Phone: {shopInfo.phone}</a>
                </p>
                <p>
                  <a href={email}>Email: {shopInfo.email}</a>
                </p>
                <ul className="social socialNoMargin">
                  {shopInfo.facebook && (
                    <li>
                      <a href={shopInfo.facebook}>
                        <FaFacebook size={30} />
                      </a>
                    </li>
                  )}
                  {shopInfo.instagram && (
                    <li>
                      <a href={shopInfo.instagram}>
                        <FaInstagram size={30} />
                      </a>
                    </li>
                  )}
                  {shopInfo.yelp && (
                    <li>
                      <a href={shopInfo.yelp}>
                        <FaYelp size={30} />
                      </a>
                    </li>
                  )}
                </ul>
                <Link to="/contact">
                  <Button variant="light">Book an Appointment</Button>
                </Link>
                <a
                  className="d-block mt-2"
                  href="https://squareup.com/gift/ML99NPNT3X91W/order"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  <Button variant="light">Buy a Gift Card</Button>
                </a>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 pb-30">
              <div className="footer_link_wrapper d-flex flex-wrap">
                <div className="footer_link w-100">
                  <h3 className="footer_title">Hours</h3>
                  <p>{shopInfo.hours}</p>
                  {/* <p>
                    <a
                      href={shopInfo.mapURL}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {shopInfo.contact === "Headquarters" ? (
                        <span>
                          Corporate Headquarters:
                          <br />
                          {shopInfo.address}
                        </span>
                      ) : (
                        <span>Address: {shopInfo.address}</span>
                      )}
                    </a>
                  </p> */}
                </div>
                <form
                  className="mt-4"
                  action="https://squareup.com/outreach/tLdJak/subscribe"
                  method="POST"
                  target="_blank"
                >
                  <input
                    type="email"
                    name="email_address"
                    placeholder="Your Email Address"
                    style={{
                      height: "39px",
                      width: "190px",
                      padding: "0 16px",
                      fontSize: "14px",
                      border: "1px solid #bec3c8",
                      borderRadius: "5px",
                      marginRight: "8px",
                    }}
                  />
                  <input type="hidden" name="embed" value="true" />
                  <Button
                    className="mt-2 d-block"
                    type="submit"
                    variant="light"
                    style={{ fontSize: "14px", width: "190px" }}
                  >
                    Join Our Newsletter
                  </Button>
                </form>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6">
              <div className="footer_instagram">
                <div className="container-fluid">
                  <div className="row">
                    <h3 className="footer_title mb-25">Instagram</h3>
                    {firstNine.map((pic) => {
                      return (
                        <div key={pic} className="col-4">
                          <a href={shopInfo.instagram}>
                            <img src={pic} alt="instagram pic" />
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer_copyright">
          <div className="container">
            <div className="copyright text-center">
              <p>
                Copyright <FaCopyright />{" "}
                <a href="/" rel="nofollow">
                  Sacred Art Tattoo
                </a>
              </p>
              <p className="mt-3 m-auto">
                At Sacred Art Tattoo Hawaii, we collect information to schedule
                appointments and gather feedback after visits. Your information
                is used solely for these purposes and will not be shared with
                third parties. View our full{" "}
                <Link to="/privacy" className="text-decoration-underline">
                  Privacy Policy
                </Link>
                .
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Footer;
