import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import ScrollToTop from "./components/ScrollToTop";
import Home from "./pages/Home";
import About from "./pages/About";
import Services from "./pages/Services";
import Artists from "./pages/Artists";
import Locations from "./pages/Locations";
import Contact from "./pages/Contact";
import BigGallery from "./pages/BigGallery";
import Piercing from "./pages/Piercing";
import Faq from "./pages/Faq";
import Error from "./pages/Error";
import PromoPage from "./pages/PromoPage";
import background from "./assets/images/SA.png";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";

function App() {
  return (
    <BrowserRouter>
      <div
        style={{
          backgroundImage: `url(${background})`,
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
          backgroundPosition: "center",
          backgroundPositionY: "75%",
          backgroundSize: "60vh",
        }}
      >
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/artists" element={<Artists />} />
          <Route path="/locations" element={<Locations />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/gallery" element={<BigGallery />} />
          <Route path="/piercing" element={<Piercing />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="*" element={<Error />} />
          <Route path="/specials" element={<PromoPage />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/terms" element={<Terms />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
